<template>
  <div class="all_main_wrap">
    <!-- 头部 -->
    <div class="all_header_box">
      <div class="sider_left">
        <span class="title">
          <router-link
            class="crumbs_item"
            tag="a"
            to="/admin/Commodity/ProductList"
          >
            商品列表 /</router-link
          >
          <span class="crumbs_item crumbs_last">
            {{ operations != 1 ? "编辑商品" : "新建商品" }}</span
          >
        </span>
      </div>
    </div>
    <!-- 功能区 -->
    <div class="content_box" >
      <a-row>
        <a-col :span="2">
          <p class="left_name required">商品名称：</p>
        </a-col>
        <a-col :span="6">
          <a-input v-model="fromOBJ.name" placeholder="请输入商品名称" />
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="2">
          <p class="left_name">推荐语：</p>
        </a-col>
        <a-col :span="12">
          <a-textarea
            v-model="fromOBJ.abstracts"
            placeholder="请输入推荐语"
            :maxLength="75"
            :auto-size="{ minRows: 3, maxRows: 5 }"
            style="resize: none"
          />
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="2">
          <p class="left_name">广告语：</p>
        </a-col>
        <a-col :span="12">
          <a-textarea
            v-model="fromOBJ.slogan"
            placeholder="请输入广告语"
            :maxLength="25"
            :auto-size="{ minRows: 3, maxRows: 5 }"
            style="resize: none"
          />
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="2">
          <p class="left_name">商品标签：</p>
        </a-col>
        <a-col :span="12">
          <a-tree-select v-model="treeData" style="width:100%;"
          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" :tree-data="productTagList" tree-checkable
          placeholder="请选择商品标签" :replaceFields="{
            title: 'tagName', key: 'tagId', value: 'tagId'
          }" :treeDefaultExpandAll="true" @change="onTagsChange" />
        </a-col>
      </a-row>
      <a-row class="flex_Div" >
        <!-- <a-col :span="3" class="flex_left_Div">
          <p class="left_name required">移动端：</p>
          <p style="font-size: 14px">用于精选中套餐包含商品列表</p>
          <p style="font-size: 14px">(建议尺寸750*530，最多可传1张)</p>
        </a-col>
        <a-col :span="6">
          <span
            class="ant-upload-picture-card-wrapper"
            v-if="fromOBJ.listPhoto"
          >
            <div
              class="
                ant-upload ant-upload-select ant-upload-select-picture-card
              "
            >
              <span role="button" tabindex="0" class="ant-upload">
                <div class="pictures">
                  <img
                    style="max-height: 100px; max-width: 100px"
                    :src="fromOBJ.listPhoto"
                    alt="avatar"
                  />
                  <div class="icon">
                    <a
                      :href="fromOBJ.listPhoto"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <a-icon type="eye" class="i" />
                    </a>
                    <a-icon
                      @click="fromOBJ.listPhoto = ''"
                      type="delete"
                      class="i"
                    />
                  </div>
                </div>
              </span>
            </div>
          </span>
          <a-upload
            v-else
            name="mobileListPicture"
            list-type="picture-card"
            :showUploadList="false"
            accept=".jpg,.png,.jpeg,.JPG,.PNG,.JPEG"
            :beforeUpload="beforeUpload"
            :customRequest="customRequestMobile"
          >
            <div>
              <a-icon :type="loadingMobile ? 'loading' : 'plus'" />
              <div class="ant-upload-text">上传图片</div>
            </div>
          </a-upload>
        </a-col> -->

        <a-col :span="3" class="flex_left_Div">
          <!-- <p class="left_name required">移动端：</p> -->
          <p class="left_name required">详情页主图：</p>
          <!-- <p style="font-size: 14px">用于商城列表、收藏列表、商品详情</p> -->
          <p style="font-size: 14px">(建议尺寸750*750，最多传5张，顺序可调)</p>
        </a-col>
        <!-- :customRequest="customRequest2" -->
        <a-col :span="12">
          <a-upload
            :remove="appremove"
            list-type="picture-card"
            :fileList="fileList"
            accept=".jpg,.png,.jpeg,.JPG,.PNG,.JPEG"
            :beforeUpload="beforeUpload"
            :customRequest="change"
          >
            <div v-if="fileList.length < 5">
              <a-icon :type="loading2 ? 'loading' : 'plus'" />
              <div class="ant-upload-text">上传图片</div>
            </div>
          </a-upload>
        </a-col>
      </a-row>

      <a-row class="flex_Div">
        <a-col :span="3" class="flex_left_Div">
          <!-- <p class="left_name required">移动端：</p> -->
          <p class="left_name required">列表长图：</p>
          <!-- <p style="font-size: 14px">用于首页重磅课程、热门课程</p> -->
        </a-col>
        <a-col :span="6">
          <ImageUpload 
            bucket="ueditor/product/" 
            :value="fromOBJ.homePage" 
            :isCropp="true" 
            :autoCropWidth="450"
            :autoCropHeight="258"
            fileScale="2:1.5"
            dialogWidth="550px"
            filePixel="450*258"
            :fileSize=2
            :isShowTip="true"
            @input="handleBanner($event,'homePage')" />
        </a-col>

        <a-col :span="3" class="flex_left_Div">
          <!-- <p class="left_name required">PC端：</p> -->
          <p class="left_name required">列表方图：</p>
          <!-- <p style="font-size: 14px">
            用于商城列表图、首页列表图、收藏列表图、重磅课程
          </p> -->
          <!-- <p style="font-size: 14px">（建议尺寸360*313，最多传1张）</p> -->
        </a-col>
        <a-col :span="12">
          <ImageUpload 
              bucket="ueditor/product/" 
              :value="fromOBJ.pcPhoto" 
              :isCropp="true" 
              :autoCropWidth="300"
              :autoCropHeight="300"
              fileScale="1:1"
              dialogWidth="400px"
              filePixel="300*300"
              :fileSize=10
              :isShowTip="true"
              @input="handleBanner($event,'pcPhoto')" />
        </a-col>
      </a-row>
      <!-- <a-row class="flex_Div">
        <a-col :span="3" class="flex_left_Div">
          <p class="left_name required">移动端：</p>
          <p style="font-size: 14px">用于商品列表方图</p>
          <p style="font-size: 14px">（建议尺寸450*258，最多传1张）</p>
        </a-col>
        <a-col :span="6">
          <span class="ant-upload-picture-card-wrapper" v-if="fromOBJ.squarePicture">
            <div
              class="
                ant-upload ant-upload-select ant-upload-select-picture-card
              "
            >
              <span role="button" tabindex="0" class="ant-upload">
                <div class="pictures">
                  <img
                    style="max-height: 100px; max-width: 100px"
                    :src="fromOBJ.squarePicture"
                    alt="avatar"
                  />
                  <div class="icon">
                    <a
                      :href="fromOBJ.squarePicture"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <a-icon type="eye" class="i" />
                    </a>
                    <a-icon
                      @click="fromOBJ.squarePicture = ''"
                      type="delete"
                      class="i"
                    />
                  </div>
                </div>
              </span>
            </div>
          </span>
          <a-upload
            v-else
            name="mobileListPicture"
            list-type="picture-card"
            :showUploadList="false"
            accept=".jpg,.png,.jpeg,.JPG,.PNG,.JPEG"
            :beforeUpload="beforeUpload"
            :customRequest="customRequestsquarePage"
          >
            <div>
              <a-icon :type="loadingsquarePage ? 'loading' : 'plus'" />
              <div class="ant-upload-text">上传图片</div>
            </div>
          </a-upload>
        </a-col>
      </a-row> -->

      <a-row class="flex_Div" v-if="false">
        <a-col :span="3" class="flex_left_Div">
          <p class="left_name required">PC端：</p>
          <p style="font-size: 14px">用于商品详情、热门推荐</p>
          <p style="font-size: 14px">
            （建议尺寸750*750，最多传5张，顺序可调）
          </p>
        </a-col>
        <a-col :span="6">
          <a-upload
            :remove="pcremove"
            list-type="picture-card"
            :fileList="pclistPhoto"
            accept=".jpg,.png,.jpeg,.JPG,.PNG,.JPEG"
            :beforeUpload="beforeUpload"
            :customRequest="pcchange"
          >
            <div v-if="pclistPhoto.length < 5">
              <a-icon :type="loading3 ? 'loading' : 'plus'" />
              <div class="ant-upload-text">上传图片</div>
            </div>
          </a-upload>
        </a-col>
      </a-row>
      <a-row style="margin-bottom: 0">
        <a-col :span="3">
          <p class="left_name required">app详情：</p>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="2"></a-col>
        <a-col :span="12" style="margin-top: -22px">
          <UE
            editorId="introduceEditor1"
            :initContent="ueInitText1"
            @input="getContent2"
          />
        </a-col>
      </a-row>
      <a-row style="margin-bottom: 0">
        <a-col :span="3">
          <p class="left_name required">web详情：</p>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="2"></a-col>
        <a-col :span="12" style="margin-top: -22px">
          <UE
            editorId="introduceEditor2"
            :initContent="ueInitText2"
            @input="getContent1"
          />
        </a-col>
      </a-row>

      <a-row :gutter="16">
        <a-col :span="8" class="book_flex">
          <span class="left_name required">是否是书籍：</span>
          <a-radio-group v-model="fromOBJ.isBook">
            <a-radio :value="1"> 是 </a-radio>
            <a-radio :value="0"> 否 </a-radio>
          </a-radio-group>
        </a-col>
      </a-row>

      <div v-if="fromOBJ.isBook == 1">
        <h3 class="big_title">
          书籍信息
          <span class="redtext"> (若没有填任何字段信息，终端整个模块隐藏)</span>
        </h3>
        <a-row>
          <a-col :span="7" class="book_flex"
            ><span class="left_name">书名：</span>
            <a-input
              v-model="book.bookName"
              class="right"
              placeholder="请输入书名"
            />
          </a-col>
          <a-col :span="7" class="book_flex"
            ><span class="left_name">丛书名：</span>
            <a-input
              v-model="book.bookCname"
              class="right"
              placeholder="请输入丛书名"
            />
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="7" class="book_flex"
            ><span class="left_name">ISBN编号：</span>
            <a-input
              v-model="book.isbnCode"
              class="right"
              placeholder="请输入ISBN编号"
            />
          </a-col>
          <a-col :span="7" class="book_flex"
            ><span class="left_name">定价：</span>
            <a-input-number
              class="right"
              id="inputNumber"
              placeholder="请输入定价"
              v-model="book.price"
              :min="0"
            />
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="7" class="book_flex"
            ><span class="left_name">版次：</span>
            <a-input
              v-model="book.edition"
              class="right"
              placeholder="请输入版次"
            />
          </a-col>
          <a-col :span="7" class="book_flex"
            ><span class="left_name">出版社：</span>
            <a-input
              v-model="book.press"
              class="right"
              placeholder="请输入出版社"
            />
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="7" class="book_flex"
            ><span class="left_name">内文用纸材质：</span>
            <a-input
              v-model="book.texture"
              class="right"
              placeholder="请输入内文用纸材质"
            />
          </a-col>
          <a-col :span="7" class="book_flex"
            ><span class="left_name">作者：</span>
            <a-input
              v-model="book.author"
              class="right"
              placeholder="请输入作者"
            />
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="7" class="book_flex"
            ><span class="left_name">出版时间：</span>
            <a-date-picker
              class="right"
              valueFormat="YYYY-MM-DD"
              placeholder="请输入出版时间"
              v-model="book.publicationTime"
            />
            <!-- <a-input
              
              class="right"
              placeholder="请输入出版时间"
            /> -->
          </a-col>
          <a-col :span="7" class="book_flex"
            ><span class="left_name">作者地区：</span>
            <a-input
              v-model="book.authorRegion"
              class="right"
              placeholder="请输入作者地区"
            />
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="7" class="book_flex"
            ><span class="left_name">包册数：</span>
            <a-input
              v-model="book.packageCount"
              class="right"
              placeholder="请输入包册数"
            />
          </a-col>
          <a-col :span="7" class="book_flex"
            ><span class="left_name">译者：</span>
            <a-input
              v-model="book.translator"
              class="right"
              placeholder="请输入译者"
            />
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="7" class="book_flex"
            ><span class="left_name">页数：</span>
            <a-input
              v-model="book.numberPage"
              class="right"
              placeholder="请输入页数"
            />
          </a-col>
          <a-col :span="7" class="book_flex"
            ><span class="left_name">编者：</span>
            <a-input
              v-model="book.editor"
              class="right"
              placeholder="请输入编者"
            />
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="7" class="book_flex"
            ><span class="left_name">字数（千字）：</span>
            <a-input
              v-model="book.numberWord"
              class="right"
              placeholder="请输入书字数"
            />
          </a-col>
          <a-col :span="7" class="book_flex"
            ><span class="left_name">开本/尺寸：</span>
            <a-input
              v-model="book.bookSize"
              class="right"
              placeholder="请输入开本/尺寸"
            />
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="7" class="book_flex">
            <span class="left_name">是否套装：</span>
            <a-select v-model="book.suit" placeholder="请选择" class="right">
              <a-icon slot="suffixIcon" type="caret-down" />
              <a-select-option :value="1">是</a-select-option>
              <a-select-option :value="0">否</a-select-option>
            </a-select>
          </a-col>
          <a-col :span="7" class="book_flex"
            ><span class="left_name">包装：</span>
            <a-input
              v-model="book.wrap"
              class="right"
              placeholder="请输入包装"
            />
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="7" class="book_flex"
            ><span class="left_name">CIP分类：</span>
            <a-input
              v-model="book.cipCategory"
              class="right"
              placeholder="请输入CIP分类"
            />
          </a-col>
          <a-col :span="7" class="book_flex"
            ><span class="left_name">印张：</span>
            <a-input
              v-model="book.stamper"
              class="right"
              placeholder="请输入印张"
            />
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="7" class="book_flex"
            ><span class="left_name">品牌：</span>
            <a-input
              v-model="book.trademark"
              class="right"
              placeholder="请输入品牌"
            />
          </a-col>
          <a-col :span="7" class="book_flex"
            ><span class="left_name">关键字：</span>
            <a-input
              v-model="book.keyword"
              class="right"
              placeholder="请输入关键字"
            />
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="7" class="book_flex"
            ><span class="left_name">读者对象：</span>
            <a-input
              v-model="book.reader"
              class="right"
              placeholder="请输入读者对象"
            />
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="14" class="book_flex">
            <span class="left_name">编辑推荐：</span>
            <a-textarea
              v-model="book.recommend"
              class="right"
              placeholder="请输入编辑推荐"
              :auto-size="{ minRows: 3, maxRows: 5 }"
              style="resize: none"
            />
          </a-col>
        </a-row>
      </div>

      <!-- <a-row>
        <a-col :span="14" class="book_flex">
          <span class="left_name required"
            >偏好设置<span class="greytext">（可多选）</span>：</span
          >
          <a-checkbox-group v-model="preferenceList">
            <a-checkbox
              v-for="(item, index) in preference"
              :key="index"
              :value="item.likeId"
              >{{ item.likeLabelName }}
            </a-checkbox>
          </a-checkbox-group>
        </a-col>
      </a-row> -->
      <!-- 商品购买设置 -->
      <h3 class="big_title">商品购买设置</h3>
      <a-row :gutter="16">
        <a-col :span="8" class="book_flex">
          <span class="left_name required">商品类别：</span>
          <a-tree-select
            v-model="categoryList"
            class="right"
            :tree-data="goodsClass"
            tree-checkable
            search-placeholder="Please select"
            placeholder="请选择商品类别"
            :replaceFields="replaceFields"
          />
          <!-- :show-checked-strategy="SHOW_PARENT" -->
          <!-- <a-select
            v-model="fromOBJ.categoryList"
            placeholder="请选择商品类别"
            mode="multiple"
            @change="handleChange"
          >
            <a-icon slot="suffixIcon" type="caret-down" />
            <a-select-option v-for="(item, index) in goodsClass" :key="index">
              {{ item.name }}
            </a-select-option>
          </a-select> -->
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="8" class="book_flex">
          <span class="left_name required">发货时间：</span>
          <a-input
            v-model="fromOBJ.shipments"
            class="right"
            placeholder="请填写发货时间"
          />
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="16" class="book_flex">
          <span class="left_name"></span>
          <p style="font-size: 12px; color: red; margin-top: -14px">
            告知用户发货时间，如遇节假日可对发货时间进行编辑修改
          </p>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="8" class="book_flex">
          <span class="left_name required">运费设置：</span>
          <a-select
            placeholder="请选择运费模板"
            v-model="fromOBJ.freightId"
            class="right"
          >
            <a-icon slot="suffixIcon" type="caret-down" />
            <a-select-option
              v-for="(item, index) in templateFreight"
              :key="index + 'tp'"
              :value="item.freightId"
              >{{ item.name }}</a-select-option
            >
          </a-select>
          <!-- <a-radio-group v-model="fromOBJ.freightSet">
            <a-radio :value="1"> 包邮 </a-radio>
            <a-radio :value="2"> 自选运费模板 </a-radio>
          </a-radio-group> -->
        </a-col>
        <!-- <a-col v-if="fromOBJ.freightSet == 2" :span="5" class="book_flex">
          
        </a-col> -->
      </a-row>
      <a-row :gutter="16">
        <a-col :span="8" class="book_flex">
          <span class="left_name required">原价：</span>
          <a-input-number
            class="right"
            id="inputNumber"
            placeholder="请输入原价"
            v-model="fromOBJ.originalPrice"
            :min="0.01"
          />
        </a-col>
        <a-col :span="8" class="book_flex">
          <span class="required">是否在终端显示</span>
          <a-radio-group v-model="fromOBJ.original">
            <a-radio :value="1"> 是 </a-radio>
            <a-radio :value="0"> 否 </a-radio>
          </a-radio-group>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="10" class="book_flex">
          <span class="left_name required">优惠价：</span>
          <a-input-number
            class="right"
            id="inputNumber"
            placeholder="请输入优惠价格"
            v-model="fromOBJ.couponPrice"
            :min="0.01"
          />
        </a-col>
      </a-row>
      <a-row :span="10">
        <p
          style="
            font-size: 12px;
            color: red;
            margin-left: 142px;
            margin-top: -14px;
          "
        >
          该价格为用户支付价格，不得设置大于原价
        </p>
      </a-row>
      <a-row>
        <a-col :span="16" class="book_flex">
          <span class="left_name required">商品编码：</span>
          <a-select
            placeholder="商品编码"
            v-model="fromOBJ.codeType"
            style="width: 20%"
          >
            <a-icon slot="suffixIcon" type="caret-down" />
            <a-select-option :value="1">a-自产</a-select-option>
            <a-select-option :value="2">b-外采</a-select-option>
          </a-select>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="8" class="book_flex">
          <span class="left_name required">书代号：</span>
          <a-input
            v-model="fromOBJ.bookCode"
            class="right"
            placeholder="请填写书代号"
          />
        </a-col>
      </a-row>
      <!-- <a-row>
        <a-col :span="8" class="book_flex">
          <span class="left_name">库存：</span>
          <a-input-number
            class="right"
            id="inputNumber"
            placeholder="请输入库存"
            v-model="fromOBJ.inventory"
            :min="0"
          />
        </a-col>
      </a-row> -->
      <a-row>
        <a-col :span="9" class="book_flex">
          <span class="left_name required">重量：</span>
          <a-input-number
            class="right"
            id="inputNumber"
            placeholder="请输入重量"
            v-model="fromOBJ.weight"
            :min="0"
          />
          （kg）
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="8" class="book_flex">
          <span class="left_name required">单位：</span>
          <a-input
            v-model="fromOBJ.unit"
            class="right"
            placeholder="请输入单位"
          />
        </a-col>
      </a-row>
      <!-- 系列购买 -->
      <!-- <div class="series_box">
        <div class="head">
          <span>推荐购买</span>
          <a-button type="primary" class="btn" @click="relationFilling(1)"
            >新建</a-button
          >
        </div>
        <a-table
          class="table-template"
          :rowKey="(item) => item.productId"
          :columns="columns"
          bordered
          :data-source="tableDatas"
          :pagination="false"
        >
          <template slot="index" slot-scope="item, row, i">
            <div style="text-align: center">
              {{ (pageNumber - 1) * pageSize + i + 1 }}
            </div>
          </template>
          <template slot="productType" slot-scope="item"> -->
            <!-- 1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考 8.直播 9.其他 -->
            <!-- <span v-if="item == 1">课程</span>
            <span v-else-if="item == 2">图书</span>
            <span v-else-if="item == 3">教具</span>
            <span v-else-if="item == 4">模拟考试</span>
            <span v-else-if="item == 5">电子照片</span>
            <span v-else-if="item == 6">延期</span>
            <span v-else-if="item == 7">补考</span>
            <span v-else-if="item == 8">直播</span>
            <span v-else-if="item == 9">其他</span>
            <span v-else>-</span>
          </template>
          <template slot="operation" slot-scope="item, row, index">
            <div class="blueText">
              <span @click="deleteRelation1(index)"> 删除</span>
            </div>
          </template>
        </a-table> -->

        <!-- <table class="table1">
          <tr class="trtitle">
            <td class="td1">序号</td>
            <td class="td2">选择系列商品</td>
            <td class="td3">操作</td>
          </tr>
          <tr class="lititle" v-for="(item, index) in tableData" :key="index">
            <td class="trd1">{{ index }}</td>
            <td class="trd2">选择系列商品</td>
            <td class="trd3">
              <a> 删除</a>
            </td>
          </tr>
        </table> -->
      <!-- </div> -->

      <!-- 关联赠品 -->
      <!-- <div class="series_box">
        <div class="head">
          <span>关联赠品</span>
          <a-button type="primary" class="btn" @click="relationFilling(2)"
            >选择商品</a-button
          >
        </div>
        <a-table
          class="table-template"
          :rowKey="(item, index) => index"
          :columns="columns"
          bordered
          :data-source="tableDatar"
          :pagination="false"
        >
          <template slot="index" slot-scope="item, row, i">
            <div style="text-align: center">
              {{ (pageNumber - 1) * pageSize + i + 1 }}
            </div>
          </template>
          <template slot="productType" slot-scope="item"> -->
            <!-- 1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考 8.直播 9.其他 -->
            <!-- <span v-if="item == 1">课程</span>
            <span v-else-if="item == 2">图书</span>
            <span v-else-if="item == 3">教具</span>
            <span v-else-if="item == 4">模拟考试</span>
            <span v-else-if="item == 5">电子照片</span>
            <span v-else-if="item == 6">延期</span>
            <span v-else-if="item == 7">补考</span>
            <span v-else-if="item == 8">直播</span>
            <span v-else-if="item == 9">其他</span>
            <span v-else>-</span>
          </template>
          <template slot="operation" slot-scope="item, row, index">
            <div class="blueText">
              <span @click="deleteRelation2(index)"> 删除</span>
            </div>
          </template>
        </a-table>
      </div> -->

      <!-- 是否可单独购买 -->
      <div class="series_box" style="margin-bottom: 20px">
        <div class="head">
          <span class="required">是否可单独购买</span>
        </div>
        <div class="right-box">
          <a-radio-group v-model="fromOBJ.isGift">
            <a-radio :value="0">可单独购买</a-radio>
            <a-radio :value="1">不可单独购买</a-radio>
          </a-radio-group>
        </div>
      </div>

      <div class="series_box">
        <div class="head">
          <span class="required">上架时间</span>
        </div>
        <div class="right-box">
          <a-radio-group v-model="fromOBJ.ground">
            <a-radio :value="1">立即上架</a-radio>

            <a-radio :value="2">暂不上架</a-radio>

            <a-radio :value="3">设置上架时间</a-radio>
            <a-date-picker
              :disabled="fromOBJ.ground != 3"
              valueFormat="YYYY-MM-DD HH:mm:ss"
              :disabled-date="disabledDate"
              :disabled-time="disabledDateTime"
              :show-time="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"
              v-model="fromOBJ.groundTime"
              placeholder="请设置上架时间"
            />
          </a-radio-group>
        </div>
      </div>

      <!-- 机构购买设置 -->
      <!-- <div class="series_box">
        <div class="head">
          <span>机构购买设置</span>
        </div>
        <div class="table">
          <a-row>
            <a-col :span="8">
              <a-radio-group name="radioGroup" :default-:value="1" class="book_flex">
                <a-radio ::value="1">不允许机构购买</a-radio>
                <a-radio ::value="2">允许机构购买</a-radio>
              </a-radio-group>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="8" class="series_flex">
              <span class="required">机构价:</span>
              <a-input-number id="inputNumber" class="center" :min="1" :max="9999999" />
              <span>元</span>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="8" class="series_flex">
              <a-radio>需达到
                <a-input-number id="inputNumber" class="center" :min="1" :max="9999999" />本购买方可使用机构价
              </a-radio>
            </a-col>
          </a-row>
          <a-row class="series_flex">
            <a-col :span="6">
              <span>以兑换券形式购买：</span>
            </a-col>
            <a-col :span="13" class="series_flex">
              <a-radio-group name="radioGroup" :default-:value="1">
                <a-radio :value="1">否</a-radio>
                <a-radio :value="2">是</a-radio>
              </a-radio-group>
              <a-select placeholder="选择兑换券" style="width:200px">
                <a-icon slot="suffixIcon" type="caret-down" />
                <a-select-option :value="1">A自产</a-select-option>
              </a-select>
            </a-col>
          </a-row>
          <a-row class="series_flex">
            <a-col :span="4">
              <span class="required">上架时间：</span>
            </a-col>
            <a-col :span="20" class="series_flex">
              <a-radio-group name="radioGroup" :default-:value="1">
                <a-radio :value="1">立即上架</a-radio>
                <a-radio :value="2">暂不上架</a-radio>
              </a-radio-group>
              <span class="time">设置上架时间</span>
              <a-date-picker />
            </a-col>
          </a-row>
        </div>
      </div> -->
    </div>

    <a-modal
      v-model="relation"
      width="1000px"
      title="选择商品"
      :centered="true"
      :closable="false"
      @cancel="categoryCancel"
    >
      <div class="search-box">
        <a-select
          v-if="popup == 1"
          class="search-input"
          placeholder="请选择类型"
          v-model="categorytype"
        >
          <a-icon slot="suffixIcon" type="caret-down" />
          <a-select-option
            v-for="item in cgoodList"
            :key="item.id"
            :value="item.id"
          >
            {{ item.classify }}
          </a-select-option>
        </a-select>
        <a-input
          class="search-input"
          placeholder="请输入名称"
          v-model="categoryname"
        />
        <a-button class="search-btn" type="primary" @click="onSearch()"
          >搜索</a-button
        >
      </div>

      <div class="table-box">
        <a-table
          class="table-template one"
          :row-selection="{
            columnTitle: '选择',
            selectedRowKeys: selectedRowKey,
            onChange: select,
          }"
          :rowKey="(item) => item.productId"
          :columns="categorycolumns"
          :data-source="categoryData"
          :pagination="{
            total: ctotal,
            current: cpageNumber,
            pageSize: cpageSize,
            showTotal: (res) => `共${ctotal}条`,
          }"
          bordered
          @change="oncPage"
        >
          <template slot="num">
            商品名称
            <!-- {{ popup == 1 ? "" : popup == 2 ? "套餐名称" : "直播名称" }} -->
          </template>
          <template slot="productType" slot-scope="item">
            <!-- 1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考 8.直播 9.其他 -->
            <span v-if="item == 1">课程</span>
            <span v-else-if="item == 2">图书</span>
            <span v-else-if="item == 3">教具</span>
            <span v-else-if="item == 4">模拟考试</span>
            <span v-else-if="item == 5">电子照片</span>
            <span v-else-if="item == 6">延期</span>
            <span v-else-if="item == 7">补考</span>
            <span v-else-if="item == 8">直播</span>
            <span v-else-if="item == 9">其他</span>
            <span v-else>-</span>
          </template>
          <template slot="index" slot-scope="item, row, index">
            {{ (cpageNumber - 1) * cpageSize + index + 1 }}
          </template>
        </a-table>

        <a-table
          class="table-template two"
          :rowKey="(item) => item.productId"
          :columns="selectcolumns"
          :data-source="selectData"
          :pagination="{
            pageSizeOptions: ['10', '20', '30', '50', '100'],
            pageSize: spageSize,
          }"
        >
          <template slot="num">
            当前已选中
            <span class="blueText">{{ selectData.length }}</span> 个商品
            <!-- {{ popup == 1 ? "" : popup == 2 ? "套餐" : "直播"   }} -->
          </template>
          <!-- <template slot="operation" slot-scope="item, row">
            <span class="blueText" @click="deleteGoods(row)">清除</span>
          </template> -->
        </a-table>
      </div>

      <template slot="footer">
        <a-button type="primary" @click="categoryOk()">确认</a-button>
        <a-button @click="categoryCancel">取消</a-button>
      </template>
    </a-modal>

    <div class="button">
      <a-button
        v-hasPermi="['commodity:info:add']"
        type="primary"
        class="btn"
        :loading="formloading"
        @click="pushData()"
        >确认</a-button
      >
      <a-button class="all_boder_btn btn" @click="$router.go(-1)"
        >取消</a-button
      >
    </div>
  </div>
</template>

<script>
import { TreeSelect } from "ant-design-vue";
const SHOW_PARENT = TreeSelect.SHOW_PARENT;
const columns = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    scopedSlots: {
      customRender: "index",
    },
  },
  {
    title: "选择推荐商品",
    align: "center",
    dataIndex: "productName",
  },
  {
    title: "商品类型",
    align: "center",
    dataIndex: "productType",
    scopedSlots: { customRender: "productType" },
  },

  {
    title: "操作",
    align: "center",
    width: "200px",
    scopedSlots: {
      customRender: "operation",
    },
  },
];

const columns2 = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    scopedSlots: {
      customRender: "index",
    },
  },
  {
    title: "商品名称",
    align: "center",
    dataIndex: "code1",
  },
  {
    title: "商品图片",
    align: "center",
    width: "200px",
    scopedSlots: {
      customRender: "picture",
    },
  },
];
const popupcolumns = [
  {
    title: "序号",
    align: "center",
    dataIndex: "index",
    scopedSlots: {
      customRender: "index",
    },
  },
  {
    title: "商品名称",
    align: "center",
    dataIndex: "info",
  },
];
const selectcolumns = [
  {
    align: "center",
    dataIndex: "productName",
    width: 120,
    colSpan: 2,
    scopedSlots: {
      customRender: "info",
      title: "num",
    },
  },
  {
    title: "操作",
    align: "center",
    colSpan: 0,
    width: 20,
    dataIndex: "operation",
    scopedSlots: {
      customRender: "operation",
    },
  },
];
const popupData = [
  {
    key: "1",
    info: "康复医疗",
    photo: 32,
  },
  {
    key: "2",
    info: "康复医疗",
    photo: 42,
  },
  {
    key: "3",
    info: "康复医疗",
    photo: 32,
  },
];

const categorycolumns = [
  {
    title: "序号",
    align: "center",
    dataIndex: "index",
    scopedSlots: {
      customRender: "index",
    },
  },
  {
    align: "center",
    dataIndex: "productName",
    scopedSlots: {
      title: "num",
    },
  },
  {
    title: "商品类型",
    align: "center",
    dataIndex: "productType",
    scopedSlots: { customRender: "productType" },
  },
];
import UE from "@/components/UE.vue";
import moment from "moment";

export default {
  // 可用组件的哈希表
  components: {
    UE,
  },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      formloading: false,
      loading: false,
      imageUrl: undefined,
      ueInitText1: "",
      ueInitText2: "",
      selectVisible: false, // 选择商品弹窗
      relation: false,
      selectcolumns,
      popupcolumns,
      popupData,
      ptotal: 0,
      ppageNumber: 1, //页码
      ppageSize: 10, // 页数
      columns, // 表头
      columns2,
      loading: false, // 列表加载
      total: 0, // 总数量
      pageNumber: 1, //页码
      pageSize: 10, // 页数
      value: "",
       // 商品标签
      productTagList: [],
      treeData: [],
      // 偏好
      preference: [],
      preferenceId: [],
      // 商品分类
      goodsClass: [],
      // 运费模板
      templateFreight: [],
      // 需要传输的数据
      fromOBJ: {
        name: "", //	商品名称
        type: "", // 2图书 3.教具 9.组合商品
        abstracts: "", //简介
        slogan: "", // 广告语
        productTagsVOList: [], // 商品标签
        pcDetail: null, //PC端商品详情
        h5Detail: null, //h5端商品详情
        homePage: null, // 首页推荐图
        // squarePicture: null, // 商品列表图
        categoryId: null, //分类编码
        productId: null, //	商品编码
        // inventory: null, //	库存
        weight: null, //	重量
        unit: null, //	单位
        codeType: null, //	1.自产2.外采
        // code: null, //	商品编码
        isBook: null, //	是否书籍
        shipments: null, //	发货时间
        // freightSet: null, //	运费设置
        freightId: undefined, //	关联运费模版
        originalPrice: null, //	原价
        original: null, //	是否在终端显示
        couponPrice: null, //	优惠价
        listPhoto: null, //	单h5文件图片
        pcPhoto: null, //	单pc文件图片
        ground: null, //	上架类型
        isGift: null, // 是否可单独购买
        groundTime: null, //	上架时间
        photoList: [],
        type:'',
      },
      categoryList: [], //	商品id

      // preferenceList: [], //	偏好

      // 书籍信息
      book: {
        author: "", //	作者
        authorRegion: "", //	作者地区
        bookCname: "", //	丛书名
        bookName: "", // 书名
        bookSize: "", // 开本/尺寸
        cipCategory: "", // CIP分类
        edition: "", // 版次
        editor: "", // 编者
        id: "", // 书籍
        isbnCode: "", // ISBN编码
        keyword: "", // 关键字
        numberPage: "", // 页数
        numberWord: "", // 字数
        packageCount: "", // 包册数
        press: "", // 出版社
        price: "", // 定价
        productId: "", // 商品id
        publicationTime: "", // 出版时间
        reader: "", // 读者对象
        recommend: "", // 编辑推荐
        stamper: "", // 印张
        suit: "", // 是否套装0否1是
        texture: "", // 内文用纸材质
        trademark: "", // 品牌
        translator: "", // 译者
        wrap: "", // 包装
      },
      // 关联的商品 详情图片信息
      photoList: [],
      // 替换字段
      replaceFields: {
        title: "name",
        value: "id",
        key: "id",
      },
      SHOW_PARENT,

      //商品弹窗
      popup: "",
      category: false,
      categoryname: "",
      categorytype: undefined,
      categorycolumns, //选择商品
      categoryData: [],
      ctotal: 0,
      cpageNumber: 1, //页码
      cpageSize: 5, // 页数
      //键值密钥
      selectedRowKey: [],
      // 列表数据
      selectData: [],

      selectedRowKeys: [], // 保存键值密钥1
      tableDatas: [], // 保存的列表数据1

      tableDatar: [], // 保存的列表数据2
      selectedRowKeyr: [], // 保存键值密钥2

      selectcolumns, //已选商品
      spageNumber: 1, //页码
      spageSize: 5, // 页数
      //商品类别
      goodList: [
        { id: 1, classify: "课程" },
        { id: 2, classify: "图书" },
        { id: 3, classify: "教具" },
        { id: 4, classify: "模拟考试" },
        { id: 5, classify: "电子照片" },
        { id: 6, classify: "延期" },
        { id: 7, classify: "补考" },
        { id: 8, classify: "直播" },
      ],
      cgoodList: [
        // { id: 0, classify: "全部" },
        { id: 1, classify: "课程" },
        { id: 2, classify: "图书" },
        { id: 3, classify: "教具" },
        { id: 4, classify: "模拟考试" },
        // { id: 5, classify: "电子照片" },
        // { id: 6, classify: "延期" },
        // { id: 7, classify: "补考" },
        // { id: 8, classify: "直播" },
      ],
      pclistPhoto: [],
      fileList: [],
      // 保存的标志位
      dataFlag: true,
      loadingMobile: false,
      loadinghomePage: false,
      loadingsquarePage: false,
      loading2: false,
      loading3: false,
      file: {},
      pcfile: {},
      operations: 0,
      id: null, // 查询详情id
    };
  },
  // 事件处理器
  methods: {
    moment,
    handleBanner(url ,name){
      this.$set(this.fromOBJ, name, url)
    },
    // 图片删除回调
    appremove(e) {
      this.fileList.map((item, index) => {
        if (item.uid == e.uid) {
          this.fileList.splice(index, 1);
        }
      });
    },
    pcremove(e) {
      this.pclistPhoto.map((item, index) => {
        if (item.uid == e.uid) {
          this.pclistPhoto.splice(index, 1);
        }
      });
    },
    range(start, end) {
      const result = [];
      for (let i = start; i < end; i++) {
        result.push(i);
      }
      return result;
    },
    disabledDate(current) {
      // Can not select days before today and today
      return current && current < moment().subtract(1, "day");
    },

    // disabledDateTime() {
    //   return {
    //     disabledHours: () => this.range(0, 24).splice(4, 20),
    //     disabledMinutes: () => this.range(30, 60),
    //     disabledSeconds: () => [55, 56],
    //   };
    // },
    disabledDateTime(dates, partial) {
      // console.log(dates,partial)
      // // if (type === 'start') {
      //   return {
      //     disabledHours: () => this.range(0, moment().hour()),
      //     disabledMinutes: () => this.range(0, moment().minute()),
      //     disabledSeconds: () => this.range(0, moment().second())
      //   };
      // // }
      // // return {
      //   // disabledHours: () => this.range(0, 60).splice(20, 4),
      //   // disabledMinutes: () => this.range(0, 30),
      //   // disabledSeconds: () => this.range(0, 60),
      // // };
    },
    // 时间
    // 上传前钩子上传图片
    beforeUpload(file) {
      // return this.upload.getToKen()
      if (!this.$regular.imgReg.reg.test(file.name)) {
        this.$message.warning(this.$regular.imgReg.msg);
        return false;
      }
      // return this.upload.getToKen()
      const isJpgOrPng =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg";
      if (!isJpgOrPng) {
        this.$message.error("请上传正确格式图片文件!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("图片内存大小需在2MB之内");
      }
      return isJpgOrPng && isLt2M;
    },
    // 文件上传更新进度和单文件上传初始化
    fileProgressCallback(progress, speed, name) {
      /*
       * progress 进度
       * speed 传输速度
       * name 文件名称
       * */
    },
    change(info) {
      let _this = this;
      this.file = info.file;
      this.fileList.push(info.file);
      this.loading2 = true;

      const bucket = 'ueditor/product/' // *必填 文件存储地址（地址参考cosUpload.js说明文件）
      // 腾讯云对象存储上传文件
      this.$cosUpload(info.file,bucket,this.fileProgressCallback,(url, fileName) => {
        // 上传完成
        if (url) {
          _this.fileList.forEach((item) => {
            if (item.uid == _this.file.uid) {
              item["url"] = url;
              item["fileType"] = 2; // 类型 1. 移动端 2 PC端
            }
          });
        } else {
          this.$message.error("上传失败");
        }
        this.loading2 = false;
      });
    },
    pcchange(info) {
      this.pcfile = info.file;
      this.pclistPhoto.push(info.file);
      let _this = this;
      this.loading2 = true;

      const bucket = 'ueditor/product/' // *必填 文件存储地址（地址参考cosUpload.js说明文件）
      // 腾讯云对象存储上传文件
      this.$cosUpload(info.file,bucket,this.fileProgressCallback,(url, fileName) => {
        // 上传完成
        if (url) {
          _this.pclistPhoto.forEach((item) => {
            if (item.uid == _this.pcfile.uid) {
              item["url"] = url;
              item["fileType"] = 2; // 类型 1.移动端 2 PC端
            }
          });
        } else {
          this.$message.error("上传失败");
        }
        this.loading2 = false;
      });
    },
    // 文件上传 - 移动端
    customRequestMobile(fileData) {
      this.loadingMobile = true;
      const bucket = 'ueditor/product/' // *必填 文件存储地址（地址参考cosUpload.js说明文件）
      // 腾讯云对象存储上传文件
      this.$cosUpload(fileData.file,bucket,this.fileProgressCallback,(url, fileName) => {
        // 上传完成
        if (url) {
          this.fromOBJ.listPhoto = url
        } else {
          this.$message.error("上传失败");
        }
        this.loadingMobile = false;
      });
    },
    // 文件上传 - 首页推荐图
    customRequesthomePage(fileData) {
      this.loadinghomePage = true;
      const bucket = 'ueditor/product/' // *必填 文件存储地址（地址参考cosUpload.js说明文件）
      // 腾讯云对象存储上传文件
      this.$cosUpload(fileData.file,bucket,this.fileProgressCallback,(url, fileName) => {
        // 上传完成
        if (url) {
          this.fromOBJ.homePage = url
        } else {
          this.$message.error("上传失败");
        }
        this.loadinghomePage = false;
      });
    },

    // 文件上传 - 商品列表图
    customRequestsquarePage(fileData) {
      this.loadingsquarePage = true;
      const bucket = 'ueditor/product/' // *必填 文件存储地址（地址参考cosUpload.js说明文件）
      // 腾讯云对象存储上传文件
      this.$cosUpload(fileData.file,bucket,this.fileProgressCallback,(url, fileName) => {
        // 上传完成
        if (url) {
          this.fromOBJ.squarePicture = url
        } else {
          this.$message.error("上传失败");
        }
        this.loadingsquarePage = false;
      });
    },

    // 文件上传 - PC端
    customRequestPC(fileData) {
      this.loadingPC = true;
      const bucket = 'ueditor/product/' // *必填 文件存储地址（地址参考cosUpload.js说明文件）
      // 腾讯云对象存储上传文件
      this.$cosUpload(fileData.file,bucket,this.fileProgressCallback,(url, fileName) => {
        // 上传完成
        if (url) {
          this.fromOBJ.pcPhoto = url
        } else {
          this.$message.error("上传失败");
        }
        this.loadingPC = false;
      });
    },
    // 重置文件上传 - 多文件
    customRequest2(e) {
      this.loading2 = true;

      const bucket = 'ueditor/product/' // *必填 文件存储地址（地址参考cosUpload.js说明文件）
      // 腾讯云对象存储上传文件
      this.$cosUpload(fileData.file,bucket,this.fileProgressCallback,(url, fileName) => {
        // 上传完成
        if (url) {
          this.fileList.forEach((item) => {
            if (item.uid == this.file.uid) {
              item.photo = url;
              item.type = 1; // 类型 1.移动端 2 PC端
            }
          });
        } else {
          this.$message.error("上传失败");
        }
        this.loading2 = false;
      });
    },

    // 分页
    onPage(e) {
      this.pageNumber = e.current;
      this.pageSize = e.pageSize;
    },
    handleChange() {},
    // 富文本内容有变化时触发
    getContent1(e) {
      this.$set(this.fromOBJ, "pcDetail", e);
    },
    getContent2(e) {
      // console.log(e);
      this.$set(this.fromOBJ, "h5Detail", e);
    },
    relationFilling(e) {
      if (e == 1) {
        this.dataFlag = true;
        // 列表数据
        this.selectedRowKey = this.tableDatas.map((item) => {
          return item.productId;
        });
        this.selectData = this.tableDatas;
      } else {
        this.dataFlag = false;
        // 列表数据
        this.selectedRowKey = this.tableDatar.map((item) => {
          return item.productId;
        });
        this.selectData = this.tableDatar;
      }
      this.relation = true;
    },
    categoryFilling(e) {
      this.category = true;
      this.popup = e;
      var url = "";
      if (e == 1) {
        url = "/hxclass-management/product-archives/manage/select-win";
      }
      this.$ajax({
        url: url,
        params: {
          noGround: 1,
          name: this.categoryname,
          type: this.categorytype == 0 ? "" : this.categorytype,
          page: this.cpageNumber,
          size: this.cpageSize,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.ctotal = res.data.total;
          this.categoryData = res.data.records;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 表格操作
    select(selectedRowKey, e) {
      this.selectedRowKey = selectedRowKey; // 选择的id集合
      this.$ajax({
        url: "/hxclass-management/product-archives/manage/select-win/product",
        params: {
          productIds: selectedRowKey.join(","),
        },
      }).then((res) => {
        if (res.code == "200" && res.success) {
          this.selectData = res.data;
        }
      });
    },
    //商品弹窗
    oncPage(e) {
      this.cpageNumber = e.current;
      this.cpageSize = e.pageSize;
      this.categoryFilling(this.popup);
    },
    onSearch() {
      this.categoryFilling(1);
    },
    // 确认
    categoryOk(e) {
      if (this.selectData && this.selectData.length) {
        if (this.dataFlag) {
          // 列表数据
          this.selectedRowKeys = this.selectedRowKey;
          this.tableDatas = this.selectData;
        } else {
          // 列表数据
          this.selectedRowKeyr = this.selectedRowKey;
          this.tableDatar = this.selectData;
        }
        // console.log(this.selectedRowKeys);
        // console.log(this.selectedRowKeyr);
        this.categorytype = undefined;
        this.categoryname = "";
        this.cpageNumber = 1;
        this.onSearch();
        this.relation = false;
      } else {
        this.$message.error("请选择商品");
      }
    },
    // 取消
    categoryCancel(e) {
      this.categorytype = undefined;
      this.categoryname = "";
      this.cpageNumber = 1;
      this.onSearch();
      this.relation = false;
    },
    // 清除
    deleteGoods(e) {
      // console.log(e);
      // return;
      let nbr = this.selectData.indexOf(e);
      // console.log(nbr);
      this.selectData.splice(nbr, 1);
      this.selectedRowKey.splice(nbr, 1);
    },
    // 删除已选数据1
    deleteRelation1(e) {
      this.selectedRowKeys.splice(e, 1);
      this.tableDatas.splice(e, 1);
    },
    // 删除已选数据2
    deleteRelation2(e) {
      this.selectedRowKeyr.splice(e, 1);
      this.tableDatar.splice(e, 1);
    },

    // 获取偏好
    getpreference() {
      this.$ajax({
        url: "/hxclass-management/like/all",
        method: "get",
        params: {
          // keyWord: 0,
        },
      }).then((res) => {
        // console.log(res);
        if (res.code == 200 && res.success) {
          this.preference = res.data;
        }
      });
    },
    // 获取商品分类
    getgoods() {
      this.$ajax({
        url: "/hxclass-management/product-category/manage/list",
        method: "get",
        params: {
          // page: 1,
          // size: 99999,
        },
      }).then((res) => {
        // console.log(res);
        this.goodsClass = res.data;
        // this.total = res.data.total;
      });
    },
    // 获取运费模板
    getFreight() {
      this.$ajax({
        url: "/hxclass-management/product-freight/manage/select-list",
        method: "get",
        params: {
          // page: 1,
          // size: 99999,
        },
      }).then((res) => {
        // console.log(res);
        this.templateFreight = res.data;
        // this.total = res.data.total;
      });
    },

    // 提交/编辑
    pushData() {
      if (this.formloading) {
        return;
      }
      if (this.fromOBJ.originalPrice < this.fromOBJ.couponPrice) {
        return this.$message.warning("优惠价为用户支付价格，不得设置大于原价");
      }
      if (!this.fromOBJ.name) {
        return this.$message.error("请输入商品名称");
      } 
      // else if (!this.fromOBJ.listPhoto) {
      //   return this.$message.error("请上传移动端商品主图");
      // } 
      else if (!this.fileList.length) {
        return this.$message.error("请上传详情页主图");
      } else if (!this.fromOBJ.homePage) {
        return this.$message.error("请上传列表长图");
      } else if (!this.fromOBJ.pcPhoto) {
        return this.$message.error("请上传列表方图");
      }
      // else if (!this.fromOBJ.squarePicture) {
      //   return this.$message.error("请上传商品列表图");
      // } 
      // else if (!this.fromOBJ.pcPhoto) {
      //   return this.$message.error("请上传pc端商品主图");
      // } else if (!this.pclistPhoto.length) {
      //   return this.$message.error("请上传pc端商品主图");
      // } 
      else if (!this.fromOBJ.h5Detail) {
        return this.$message.error("请输入app详情");
      } else if (!this.fromOBJ.pcDetail) {
        return this.$message.error("请输入web详情");
      } else if (this.fromOBJ.isBook === null) {
        return this.$message.error("请选择是否有书籍");
      }
      //  else if (!this.preferenceList.length) {
      //   return this.$message.error("请选择偏好设置");
      // }
       else if (!this.categoryList.length) {
        return this.$message.error("请选择商品类别");
      } else if (!this.fromOBJ.shipments) {
        return this.$message.error("请填写发货时间");
      } else if (!this.fromOBJ.freightId) {
        return this.$message.error("请选择运费模版");
      } else if (this.fromOBJ.originalPrice === null) {
        return this.$message.error("请输入原价");
      } else if (this.fromOBJ.original === null) {
        return this.$message.error("请选择原价是否在终端展示");
      } else if (this.fromOBJ.couponPrice === null) {
        return this.$message.error("请输入优惠价格");
      } else if (!this.fromOBJ.codeType) {
        return this.$message.error("请选择商品编码");
      } else if (!this.fromOBJ.bookCode) {
        return this.$message.error("请填写书代号");
      } else if (this.fromOBJ.weight === null) {
         return this.$message.error("请输入重量");
      } else if (this.fromOBJ.weight === 0) {
        return this.$message.error("请重新填写重量");
      } else if (this.fromOBJ.unit === null) {
        return this.$message.error("请输入单位");
      } else if (this.fromOBJ.isGift === null) {
        return this.$message.error("请选择是否可单独购买");
      } else if (this.fromOBJ.ground === null) {
        return this.$message.error("请选择上架时间");
      }
      if (this.fromOBJ.ground === 1 || this.fromOBJ.ground === 2) {
        this.groundTime = null;
      } else {
        if (this.fromOBJ.groundTime === null) {
          return this.$message.error("请设置上架时间");
        }
      }
      this.formloading = true;
      let arr = [];
      this.fromOBJ.book = this.book;
      // 赠品
      this.tableDatar.forEach((element) => {
        arr.push({
          relevanceId: element.productId,
          type: 2,
          // productName: element.productName,
        });
      });
      // 操作 系列商品
      this.tableDatas.forEach((element) => {
        arr.push({
          relevanceId: element.productId,
          type: 1,
          // productName: element.productName,
        });
      });
      this.fromOBJ.productList = arr;
      // 操作 重复利用省的浪费 关联分类
      arr = [];
      function mapArray(arrlist, e) {
        // console.log(arrlist,e)
        arrlist.map((item) => {
          if (item.id == e) {
            arr.push({
              categoryId: item.id, // 分类id
              categoryName: item.name, // 分类名称
            });
          }
        });
      }
      this.categoryList.forEach((element) => {
        this.goodsClass.map((item) => {
          if (item.children) {
            mapArray(item.children, element);
          } else {
            if (item.id == element) {
              arr.push({
                categoryId: item.id, // 分类id
                categoryName: item.name, // 分类名称
              });
            }
          }
        });
      });
      this.fromOBJ.categoryList = arr;
      // // 重复利用省的浪费 偏好
      // arr = [];
      // this.preferenceList.forEach((element) => {
      //   arr.push({
      //     likeId: element,
      //   });
      // });

      // this.fromOBJ.preferenceList = arr;

      // 商品标签
      let tagsArr = [];
      this.productTagList.forEach(item => {
        if (this.treeData.includes(item.tagId)) {
          tagsArr.push(item);
        }
      });
      this.fromOBJ.productTagsVOList = tagsArr;

      this.fromOBJ.photoList = [...this.fileList, ...this.pclistPhoto].map(
        (item) => {
          return {
            fileType: item.fileType,
            url: item.url,
            uid: item.uid,
          };
        }
      );
      let urls = "";
      let method = "";
      // 只有1的时候是新建
      if (this.operations != 1) {
        urls = "/hxclass-management/product-archives/manage/update";
        method = "put";
      } else {
        urls = "/hxclass-management/product-archives/manage/save";
        method = "post";
      }
      this.$ajax({
        url: urls,
        method: method,
        params: this.fromOBJ,
      }).then((res) => {
        this.formloading = false;
        if (res.code == 200 && res.success) {
          this.$message.success("操作成功");
          this.$router.go(-1);
        } else {
          this.$message.error("操作失败");
        }
      });
    },

    // 获取商品详情
    getDetail() {
      this.$ajax({
        url: "/hxclass-management/product-archives/manage/select",
        params: {
          id: this.id,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          if (res.data.book) {
            this.book = res.data.book;
          }
          if(res.data.photoList && res.data.photoList.length){
            res.data.photoList.forEach(element => {
              element.name = element.uid
            });
            this.fileList = res.data.photoList.filter((item) => {
              return item.fileType == 2;
            });
            // this.pclistPhoto = res.data.photoList.filter((item) => {
            //   return item.fileType == 2;
            // });
          }
          this.fromOBJ = res.data;
          for (let key in this.fromOBJ) {
            this.fromOBJ[key] = res.data[key];
          }
          this.ueInitText1 = res.data.h5Detail;
          this.ueInitText2 = res.data.pcDetail;
          // // 获取偏好
          // res.data.preferenceList.forEach((element) => {
          //   this.preferenceList.push(element.likeId);
          // });
          // 获取商品类别
          res.data.categoryList.forEach((element) => {
            this.categoryList.push(element.categoryId);
          });

          res.data.productTagsVOList.forEach((item) => {
            this.treeData.push(item.tagId)
          });

          // this.tableDatar.forEach((element) => {
          //   arr.push({
          //     relevanceId: element.productId,
          //     type: 1,
          //     // productName: element.productName,
          //   });
          // });
          // // 赠品
          // this.tableDatas.forEach((element) => {
          //   arr.push({
          //     relevanceId: element.productId,
          //     type: 1,
          //     // productName: element.productName,
          //   });
          // });
          // 获取关联的两部分商品，根据type区分
          if (res.data.productList) {
            res.data.productList.forEach((element) => {
              if (element.type == 1) {
                this.tableDatas.push(element);
              } else {
                this.tableDatar.push(element);
              }
            });
          }
          // this.ctableData = this.data.productList;
          // this.onMap();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 查看子项
     getProductTags() {
      this.$ajax({
        url: '/hxclass-management/dict/get_by_p',
        params: {
          p: 'product_tags'
        }
      }).then(res => {
        if (res.code == 200 && res.success) {
          let arr = []
          res.data.forEach((item) => {
            arr.push({
              tagId: Number(item.dictCode),
              tagName: item.dictValue
            });
          });
          this.productTagList = arr;
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 限制标签数量
    onTagsChange() {
      if (this.treeData.length > 4) {
        this.treeData.splice(4, 1);
        this.$message.warning('最多只可选择4个标签');
      }
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.operations = this.$route.query.operation;
    this.id = this.$route.query.id;
    this.fromOBJ.type = this.$route.query.type;
    if (this.operations != 1) {
      this.getDetail();
    }
    this.getpreference();
    this.getProductTags();
    this.getgoods();
    this.getFreight();
    this.categoryFilling(1);
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {
    rowSelection() {
      return {
        // onChange: (selectedRowKeys, selectedRows) => {
        // console.log(
        //     `selectedRowKeys: ${selectedRowKeys}`,
        //     "selectedRows: ",
        //     selectedRows
        //   );
        // },
        getCheckboxProps: (record) => ({
          props: {
            disabled: record.name === "Disabled User", // Column configuration not to be checked
            name: record.name,
          },
        }),
      };
    },
  },
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
a {
  color: #333333;
}
.redtext {
  font-size: 14px;
  color: red;
}

.greytext {
  font-size: 14px;
  color: #888;
}
.pictures {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  &:hover {
    .icon {
      opacity: 1;
    }
  }
  .icon {
    transition: all 0.3s;
    opacity: 0;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.3);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    i {
      color: #fff;
      margin: 0 5px;
      font-size: 18px;
    }
  }
}
.content_box {
  padding: 20px 0;
  .big_title {
    font-size: 18px;
    color: #333333;
    font-weight: 500;
    margin: 30px 0;
  }
}
.left_name {
  display: inline-block;
  font-weight: 500;
  line-height: 26px;
  color: #333333;
}
// 必填标识
.required::before {
  content: "*";
  display: inline-block;
  color: red;
  position: relative;
  top: 2px;
  margin-right: 2px;
}
.ant-row {
  margin-bottom: 20px;
}
.ant-col-2 {
  text-align: right;
  margin-right: 10px;
}
.flex_Div {
  display: flex;
  align-items: center;
  .flex_left_Div {
    text-align: center;
    .size {
      color: red;
      font-size: 14px;
    }
  }
}

// 书籍信息
.book_flex {
  display: flex;
  align-items: center;
  > span {
    display: inline-block;
    text-align: right;
    min-width: 142px;
  }
  .right {
    width: 100%;
  }
}

.top {
  margin: 24px 0;
}

// 系列购买
.series_box {
  margin-left: 20px;
  max-width: 800px;
  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    > span {
      display: inline-block;
      width: 140px;
      text-align: right;
      color: #333333;
    }
  }
  .table-template {
    margin-left: 74px;
    // margin-top: 20px;
    margin-bottom: 10px;
  }
  .series_flex {
    display: flex;
    align-items: center;
    .center {
      width: 80px;
      margin: 0 10px;
    }
    .time {
      font-size: 14px;
      margin-right: 10px;
    }
  }

  .all_input {
    width: 240px;
  }
  .right-box {
    display: flex;
    margin: 24px 0 0 100px;
  }
}
.picture {
  width: 120px;
}
.button {
  .btn {
    margin: 24px 14px;
  }
}
.table-box {
  display: flex;
  justify-content: space-between;
  .one {
    flex: 1;
    margin-right: 15px;
  }
  .two {
    width: 270px;
  }
}
.table-template {
  /deep/.ant-table {
    border: none;
  }
}
.search-box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  .search-input {
    width: 36%;
  }
}
.table1 {
  border-collapse: collapse;
  width: 730px;
  box-shadow: 0px 0px 4px 1px #d6d4d4;
  .trtitle {
    width: 100%;
    height: 46px;
    background-color: #f6f6fc;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
  }
  .td1 {
    width: 80px;
    height: 46px;

    text-align: center;
  }
  .td2 {
    height: 46px;
    width: 445px;
    text-align: center;
  }
  .td3 {
    height: 46px;
    width: 200px;
    text-align: center;
  }
  .lititle {
    width: 100%;
    border-bottom: #e7e7e7 solid 1px;
    font-size: 12px;
    .trd1 {
      width: 80px;
      height: 46px;
      border-right: #e7e7e7 1px solid;
      text-align: center;
    }
    .trd2 {
      height: 46px;
      width: 445px;
      text-align: center;
    }
    .trd3 {
      border-left: #e7e7e7 1px solid;
      height: 46px;
      width: 200px;
      text-align: center;
      a {
        color: #3681f0;
      }
    }
  }
}
</style>
